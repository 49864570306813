.otpContainer {
    margin: 0 auto;
    justify-content: center;
}

.titleAmount {
    font-size: 0.9375rem;
    line-height: 1.1;
    color: #1A501E;
}

.nk-tb-head .nk-tb-col {
    color: #fff;
}

.customerHeader .nk-tb-head {
    background-color: #51903F;
}

.customerHeader .tb-sub,
.policyHeader .tb-sub,
.agentHeader .tb-sub,
.assignmentHeader .tb-sub,
.pfHeader .tb-sub,
.loiHeader .tb-sub,
.premiumHeader .tb-sub {
    color: #000;
}

.policyHeader .nk-tb-head {
    background-color: #36A593;
}

.agentHeader .nk-tb-head {
    background-color: #9AACCD;
}

.assignmentHeader .nk-tb-head {
    background-color: #9DAD74;
}

.pfHeader .nk-tb-head {
    background-color: #8773B1;
}

.loiHeader .nk-tb-head {
    background-color: #C0AD62;
}

.premiumHeader .nk-tb-head {
    background-color: #B1D8DE;
}

.marginbottom15 {
    margin-bottom: 1.5em !important;
}

.print-source {
    display: none;
}

.invalid {
    color: #e85347;
    font-size: 11px;
    font-style: italic;
}

.mrignt {
    margin-right: 10px;
    cursor: pointer;
}

.nopadding {
    padding: 0 !important;
}

.alignRight {
    text-align: right;
}

.maxWidthNone {
    max-width: none !important;
}

.widthauto {
    width: auto !important;
}

.react-select__control {
    border: 1px solid #A9CDAC !important;
    border-radius: 12px !important;
}

.displayInline {
    display: inline;
}

.asd a {
    padding: 5px 20px;
}

body>.print-source {
    display: block;
}

@media print {

    .nk-header,
    .noPrint {
        display: none;
    }
}